import { render } from "./VillageList.vue?vue&type=template&id=35b4c8a0&scoped=true"
import script from "./VillageList.vue?vue&type=script&setup=true&lang=js"
export * from "./VillageList.vue?vue&type=script&setup=true&lang=js"

import "./VillageList.vue?vue&type=style&index=0&id=35b4c8a0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-35b4c8a0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "35b4c8a0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('35b4c8a0', script)) {
    api.reload('35b4c8a0', script)
  }
  
  module.hot.accept("./VillageList.vue?vue&type=template&id=35b4c8a0&scoped=true", () => {
    api.rerender('35b4c8a0', render)
  })

}

script.__file = "src/views/villageInfo/components/VillageList.vue"

export default script