<template>
  <div style="padding: 0 20px 20px 40px">
    <a-table
      :columns="statisticalColumns"
      :data-source="statisticalData"
      :pagination="pagination"
      :scroll="{ y: 700 }"
      :customRow="districtRowClick"
    >
      <template #custonAbnormalCountTmp="{ record }">
        <span style="color: red">
          {{ getAbnormalCount(record) }}
        </span>
      </template>
      <template #custonTotalCountTmp="{ record }">
        <span>
          {{ getTotalCount(record) }}
        </span>
      </template>
      <template #custonBloodPressureTmp="{ record }">
        <span style="color: red">{{ record.BloodPressureAbnormal }}</span>
        /
        <span>{{ record.BloodPressureTotal }}</span>
      </template>
      <template #custonTemperatureTmp="{ record }">
        <span style="color: red">{{ record.TemperatureAbnormal }}</span>
        /
        <span>{{ record.TemperatureTotal }}</span>
      </template>
      <template #custonBodyWeightTmp="{ record }">
        <span style="color: red">{{ record.BodyWeightAbnormal }}</span>
        /
        <span>{{ record.BodyWeightTotal }}</span>
      </template>
    </a-table>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits } from 'vue'

  const emit = defineEmits(['clickItem'])

  const props = defineProps({
    statisticalData: {
      type: Array,
      default: [],
    },
  })

  // const districtData = [...Array(29)].map((_, i) => ({
  //   key: i,
  //   name: `財團法人伊甸社會福利基金會......三峽身心障礙福利中心 ${i + 1}`,
  //   totalCount: 16900,
  //   abnormalCount: 5081,
  //   bloodPressure: `${i + 1}%`,
  //   temperature: `${i + 1}%`,
  //   bodyWeight: `${i + 1}%`,
  // }))

  const statisticalColumns = [
    {
      title: '名稱',
      dataIndex: 'title',
      key: 'title',
      // width: '150px',
      // className: 'column-title-center',
      // slots: {
      //   customRender: 'custonTitleTmp',
      // },
    },
    {
      title: '異常人次',
      dataIndex: 'BloodPressureAbnormal',
      key: 'abnormal',
      slots: {
        customRender: 'custonAbnormalCountTmp',
      },
    },
    {
      title: '量測人次',
      dataIndex: 'BloodPressureTotal',
      key: 'total',
      slots: {
        customRender: 'custonTotalCountTmp',
      },
    },
    {
      title: '血壓',
      dataIndex: 'BloodPressureAbnormal',
      key: 'bloodPressure',
      slots: {
        customRender: 'custonBloodPressureTmp',
      },
    },
    {
      title: '體溫',
      dataIndex: 'TemperatureAbnormal',
      key: 'temperature',
      slots: {
        customRender: 'custonTemperatureTmp',
      },
    },
    {
      title: 'BMI',
      dataIndex: 'BodyWeightAbnormal',
      key: 'bodyWeight',
      slots: {
        customRender: 'custonBodyWeightTmp',
      },
    },
  ]

  const districtRowClick = (record, index) => {
    return {
      style: {
        cursor: 'pointer',
      },
      onClick: (event) => {
        emit('clickItem', record.title)
        // goToVillageInfo(record)
      },
    }
  }
  // const goToVillageInfo = (record) => {
  //   this.$router.push({
  //     name: 'VillageInfo',
  //     params: {
  //       fid: 100,
  //     },
  //     query: {
  //       fromDate: this.fromDate,
  //       toDate: this.toDate,
  //       naviType: 'period',
  //     },
  //   })
  // }

  const getAbnormalCount = (record) => {
    return (
      parseInt(record.BloodPressureAbnormal, 10) +
      parseInt(record.BodyWeightAbnormal, 10) +
      parseInt(record.TemperatureAbnormal, 10)
    )
  }

  const getTotalCount = (record) => {
    return (
      parseInt(record.BloodPressureTotal, 10) +
      parseInt(record.BodyWeightTotal, 10) +
      parseInt(record.TemperatureTotal, 10)
    )
  }

  const pagination = {
    onChange: (page) => {
      console.log(page)
    },
    pageSize: 12,
  }
</script>

<style scoped>
  .card-item {
    background: #ffffff;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
  }
</style>
