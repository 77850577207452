import { render } from "./VillageCardList.vue?vue&type=template&id=c7c55a60&scoped=true"
import script from "./VillageCardList.vue?vue&type=script&setup=true&lang=js"
export * from "./VillageCardList.vue?vue&type=script&setup=true&lang=js"

import "./VillageCardList.vue?vue&type=style&index=0&id=c7c55a60&lang=css"
import "./VillageCardList.vue?vue&type=style&index=1&id=c7c55a60&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-c7c55a60"
/* hot reload */
if (module.hot) {
  script.__hmrId = "c7c55a60"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c7c55a60', script)) {
    api.reload('c7c55a60', script)
  }
  
  module.hot.accept("./VillageCardList.vue?vue&type=template&id=c7c55a60&scoped=true", () => {
    api.rerender('c7c55a60', render)
  })

}

script.__file = "src/views/villageInfo/components/VillageCardList.vue"

export default script